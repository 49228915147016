<script>
export default{
    props: [ "value", "step", "directive" ],
    methods: {
        hasSlot(slotName){
            return !!this.$slots[ slotName ] || !!this.$scopedSlots[ slotName ];
        }
    }
}
</script>

<template>
    <div class="wizard-step" v-if="value>=step">
        <div class="wizard-step-directive" v-if="value==step && directive && directive != null && directive != ''">
            <p v-html="directive"></p>
        </div>
        <div class="wizard-step-directive" v-if="value==step && hasSlot('directive')">
            <slot name="directive"></slot>
        </div>
        <slot></slot>
    </div>
</template>

<style>
    .wizard-step-directive{
        font-weight: bolder;
        font-style: italic;
        margin: 5px;
    }
</style>